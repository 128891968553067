<!--
    Created by 王丽莎 on 2024/05/16.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：外部数据-导入用户数据
-->
<style lang="less">
#outside-import-customer {
  .top-tab-outer {
    margin: -@containerGap -@containerGap @containerGap * 0.5;
    background-color: @backColorNormal;
  }
  .text-color-error {
    color: @errorColor;
    font-weight: 600;
  }
}
</style>

<template>
  <section id="outside-import-customer">
    <div class="top-tab-outer">
      <yg-tab-primary
        :dic-items="tab.items"
        :type="3"
        @on-change="onTabChange"
      />
    </div>
    <!-- 查询条件 -->
    <div>
      <yg-search-area
        v-permission="'system:importCustomer:list'"
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:importCustomer:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--导入弹窗-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      title="经营数据"
      submit-type="outsideCustomerDataType"
      @on-close="dialog.submitModel=null"
      @on-sure="onSureSubmit"
    />
  </section>
</template>

<script>
import vSubmit from './view/submit.vue';
import { OutsideImportDataModel, OutsideImportFormModel } from './model/OutsideImportDataModel';
import controllerMix from './mixins/controller';
import OutSideDataApiSet from '@/request/api/OutSideDataApiSet';
import { mapActions } from 'vuex';
import { downloadExcel } from '@/libs/download';
const PAGE_TYPE = 'outsideCustomerDataType';

export default {
  components: { vSubmit },
  mixins: [controllerMix],
  data () {
    return {
      pageType: '',
      tab: {
        items: [],
        index: 0
      },
      searchOption: {
        importDate: BaseSearchModel.initData('导入日期', 'importDate', 'DatePickerRange').setDatePickerElevator([])
      },
      dialog: {
        submitModel: null
      }
    };
  },
  computed: {
    settingItems () {
      if (this.tab.index === 0) {
        // 用户台账
        return [
          BaseSettingModel.initData('导入数据', 'import', '').setPermission('system:importCustomer:import'),
          BaseSettingModel.initData('导出记录表', 'export', '').setExport().setExportReportType(19).setPermission('system:importCustomer:export')
        ];
      }
      // 手机号变更台账
      return [
        BaseSettingModel.initData('导入数据', 'import', '').setPermission('system:importCustomer:import'),
        BaseSettingModel.initData('导出记录表', 'export', '').setExport().setExportReportType(20).setPermission('system:importCustomer:export')
      ];
    }
  },
  created () {
    this.pageType = PAGE_TYPE;
    this.initData();
  },
  methods: {
    ...mapActions(['getOutsideCustomerDataTypeList']),
    /** ******************* 工具方法开始 ******************* **/
    initData () {
      const items = [];
      this.getOutsideCustomerDataTypeList().then(res => {
        res.forEach(v => {
          items.push(v);
        });
        this.tab.items = items;
        console.log(this.tab);
      });
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onSureSubmit (params) {
      // for (let i = 0; i < 10; i++) {
      this.requestImport(params);
      // }
    },
    onTabChange (i) {
      if (this.tab.index !== i) {
        this.tab.index = i;
        this.onChangePage(1);
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSetting (key) {
      if (key === 'import') {
        const model = OutsideImportFormModel.createAdditionModel();
        model.externalDataType = this.tab.items[this.tab.index].value;
        this.dialog.submitModel = model;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OutSideDataApiSet.qryImportData;
      const s = this.searchOption;
      api.params = {
        externalDataType: this.tab.items[this.tab.index].value,
        importDateBegin: s.importDate.value[0] || '',
        importDateEnd: s.importDate.value[1] || '',
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      console.log('externalDataType', api.params.externalDataType);
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = OutsideImportDataModel.initListWithDataList(resData.rows);
        OutsideImportDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    },
    requestDownload (params, title) {
      const api = OutSideDataApiSet.downloadDealResult;
      api.params = params;
      downloadExcel(api.url, api.params, title).then().catch(e => {
        this.$Message.warning(e);
      });
    }
  }
};
</script>
